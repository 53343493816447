@import "Variables";

.modal-button-invisible-outline {
    color: $black;
    background-color: transparent;
    border-color: #ccc;
}

.modal-button-body-text {
    white-space: pre-line;
}

.modal-button-text-with-icon {
    padding-left: 0.5rem;
}