@import "Variables";

.firmware-overview-align-center-justify-left {
    display: flex;
    align-items: center;
    justify-content: left;
}

.firmware-overview-clickable-cell {
    cursor: pointer;
}