@import "Variables";

.stat {
    background-color: rgb(250, 250, 250);
    padding: 5px 20px;
    margin: 10px;
    border-radius: 7px;
    flex: 1 1 auto;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.stat-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.stat-unit {
    padding-left: 0.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.stat-value {
    font-size: 2.5rem;
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.stat-health-sh_operational {
    background-color: $color_range_002;
}

.stat-health-ok {
    background-color: $color_range_002;
}

.stat-health-sh_suspent {
    background-color: $color_range_011;
}

.stat-health-sh_degraded {
    background-color: $color_range_009;
}

.stat-health-sh_ota {
    background-color: $color_range_006;
}

.stat-health-ota {
    background-color: $color_range_006;
}

.stat-health-sh_fault {
    background-color: $color_range_014;
}

.stat-health-error {
    background-color: $color_range_014;
}

.stat-health-sh_shutdown {
    background-color: $color_range_013;
}

.stat-health-shutdown {
    background-color: $color_range_013;
}

.stat-health-offline {
    background-color: $color_range_013;
}