@import "Variables";


.update-rule-table-align-center-justify-right {
    display: flex;
    align-items: center;
    justify-content: right;
}

.update-rule-table-add-button {
    color: $black;
    height: 2rem;
    width: 2cqb;
    cursor: pointer;
}

.update-rule-clickable {
    cursor: pointer;
}