$red: #db001b;
$green: green;
$amber: #ff7f00;
$blue: #001e62;
$black: #000000;
$gray: #888888;
$yellow: #fcb817;
$dark-background-color: #202a32;
$font-color-for-dark-background: #c9cdd1;

// color range
// generated with::after
// https://hihayk.github.io/scale/#10/6/40/43/-51/67/26/14/fcb817/252/184/23/white
// settings:
//  - base: #fcb817
//  - dark colors amount: 10
//  - darkness: 40
//  - dark colors hue angle: -51
//  - dark colors saturation: 14
//  - light colors amount: 6
//  - lightness: 43
//  - light colors hue angle: 67
//  -light colors saturation: 26

$color_range_base: #fcb817;
$color_range_001: #4A9904;
$color_range_002: #5DA306;
$color_range_003: #72AD07;
$color_range_004: #88B809;
$color_range_005: #9FC20A;
$color_range_006: #B7CC0C;
$color_range_007: #D0D60E;
$color_range_008: #E0D910;
$color_range_009: #EBCF12;
$color_range_010: #F4C415;
$color_range_011: #fcb817;
$color_range_012: #FF9623;
$color_range_013: #FF7630;
$color_range_014: #FF5B3D;
$color_range_015: #FF4B53;
$color_range_016: #FF5B83;
$color_range_017: #FF6EAE;

$light-gray: #f1f4f5;
$light-red: #ffe6e8;
$white: #fafafa;

$content-background-color: rgb(240, 240, 240);
$content-advanced-background-color: lighten(rgb(206, 180, 125), 20%);
$content-width: 55rem;

$sidebar-width: 15rem;
$sidebar-visibility-min-width: 3 * $sidebar-width;