@import "Variables";


.system-interaction {
    padding-top: 3rem;
    padding-left: 2rem;
}

.system-interaction .row {
    padding-top: 0.7rem;
}

#connect-to-system {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#system-interaction-header {
    border-style: solid;
    border-width: 2px;
    border-color: $gray;
    border-radius: 5px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin-bottom: 1rem;
    background-color: darken($content-background-color, 7%);
}

#system-interaction-advanced-mode-switch {
    padding: 0.5rem;
}

.system-interaction-content-box {
    border-style: solid;
    border-width: 2px;
    border-color: $gray;
    border-radius: 5px;
    padding-bottom: 0.4rem;
}

.system-interaction-content-box-title {
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 0.5rem;

}

.system-interaction-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#system-interaction-status-box-text {
    font-size: 1.0rem;
    font-weight: 600;
}

.system-connection-alert-box {
    margin: 0 0;
}

#system-interaction-connect-to-system-text {
    font-size: 0.8rem;
    font-style: italic;
}

.system-interaction-status-box {
    font-size: 1.2rem;
    font-weight: 600;
}

.system-interaction-status-box-icon {
    height: 2rem;
    width: 2rem;
}

.system-interaction-status-connecting {
    color: $color_range_010;
}

.system-interaction-status-connected {
    color: $color_range_002;
}

.system-interaction-status-error {
    color: $color_range_014;
}

.system-interaction-advanced-mode {
    background-color: $content-advanced-background-color !important;
    border-radius: 5px;
}