#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 1rem;
    padding-bottom: 1rem;
}

.home-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}