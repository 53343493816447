@import "Variables";
@import "Themes";

// ---------------------------------------------------------------------------------------------------------------------
// GLOBAL
// ---------------------------------------------------------------------------------------------------------------------

body {
  overflow-x: hidden;
}

.removed {
  display: none !important;
}

// visible when printing
.removed-on-screen {
  display: none;
}

.hidden {
  visibility: hidden !important;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

// h1 {
//   display: none;
// }

.word-break-break-word {
  word-break: break-word;
}

.cursor-default {
  cursor: default !important;
}

@media (max-width: $sidebar-visibility-min-width) {
  h1 {
    text-align: center;
    display: block;
  }
}

// To hide content when on screen or printing
@media screen {
  .no-screen {
    display: none !important;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .removed-on-screen {
    display: initial;
  }

  h1 {
    text-align: center;
    display: block;
  }

  select {
    appearance: none !important;
    border-style: none !important;
    background-color: white !important;
  }

  input {
    border-style: none !important;
    background-color: white !important;
  }
}

.medium-size {
  min-width: 33%;
  max-width: max-content;
}

@media (max-width: $sidebar-visibility-min-width) {
  .medium-size {
    min-width: 100%;
    max-width: 100%;
  }
}

.service-button {
  background-color: $dark-background-color;
  border-color: $dark-background-color;
  color: $font-color-for-dark-background;
}

.service-button:hover {
  background-color: darken($dark-background-color, 5%);
  color: lighten($font-color-for-dark-background, 10%);
}

.service-button:active {
  background-color: darken($dark-background-color, 5%);
  color: lighten($font-color-for-dark-background, 10%);
  transform: translate(-1px, 2px);
}

.service-button.attention {
  background-color: $red;
  border-color: $red;
  color: $font-color-for-dark-background;
}

.service-button.attention:hover {
  background-color: darken($red, 5%);
  color: lighten($font-color-for-dark-background, 10%);
}

.service-button.attention:active {
  background-color: darken($dark-background-color, 5%);
  color: lighten($font-color-for-dark-background, 10%);
  transform: translate(-1px, 2px);
}

.service-table-header th {
  color: $font-color-for-dark-background;
  background-color: $dark-background-color;
  border-color: $dark-background-color;
}

.service-table tr th {
  border-bottom: 2px solid $dark-background-color;
}

.service-table tr td {
  border-bottom: 2px solid $dark-background-color;
}

// ---------------------------------------------------------------------------------------------------------------------
// NAVBAR (Top Bar)
// ---------------------------------------------------------------------------------------------------------------------
$header-topbar-height: 85px;
$header-topbar-height-collapsed: 55px;
$header-sidebar-logo-height: 85px;
$header-sidebar-logo-height-collapsed: 55px;
$header-navbar-logo-height: 35px;
$header-navbar-logo-height-collapsed: 35px;

#top-bar {
  background-color: $yellow !important;
  height: $header-topbar-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: $sidebar-visibility-min-width) {
  #top-bar {
    height: $header-topbar-height-collapsed;
  }
}

#service-navbar .container-fluid {
  padding: unset;
  justify-content: flex-start;
  flex-flow: row wrap;
}

#navbar-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: calc(($header-topbar-height-collapsed - $header-navbar-logo-height-collapsed) / 2);
}

#navbar-logo {
  height: $header-navbar-logo-height;
}

.toggled #navbar-logo {
  display: none;
}

#menu-toggle {
  border-width: 0;
  color: $blue;
  background-color: $white;
}

.topbarButton {
  text-decoration: none;
  color: $black;
}

@media (min-width: $sidebar-visibility-min-width) {
  #navbar-content {
    display: none;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// SIDEBAR
// ---------------------------------------------------------------------------------------------------------------------

// basic color settings
$sidebar-background-color: $dark-background-color;
$sidebar-font-color: $font-color-for-dark-background;
$sidebar-nav-hover-background-color: darken($sidebar-background-color, 3%);
$sidebar-nav-hover-font-color: #f5f8fa;
$sidebar-nav-active-background-color: darken($sidebar-background-color, 3%);
$sidebar-nav-active-font-color: $white;
$sidebar-nav-active-font-weight: 550;

#service-sidebar {
  margin-left: 0;
  min-height: 100vh;
  transition: margin 0.25s ease-out;
  background-color: $sidebar-background-color;
  color: $sidebar-font-color;
  display: flex;
  flex-direction: column;
}

#sidebar-menu {
  position: sticky;
  top: 0;
}

#service-sidebar .sidebar-horizontal-divider {
  border-color: lighten($sidebar-background-color, 10%);
  margin: 0 22px 0 22px;
}

#service-sidebar .list-group {
  width: 15rem;
}

#service-sidebar .list-group-item {
  background-color: $sidebar-background-color;
  color: $sidebar-font-color;
  border-width: 0;
  padding: 12px 18px 12px 22px;
  margin-top: 0;
}

#service-sidebar a {
  text-decoration: none;
}

#service-sidebar .list-group-item-secondary {
  padding: 8px 18px 8px 38px;
  font-size: smaller;
}

#service-sidebar .sidebar-reactive:hover {
  background-color: $sidebar-nav-hover-background-color;
  color: $sidebar-nav-hover-font-color;
}

#service-sidebar .active {
  background-color: $sidebar-nav-active-background-color;
  color: $sidebar-nav-active-font-color;
  font-weight: $sidebar-nav-active-font-weight;
}

#service-sidebar .active:after {
  content: "";
  background: $sidebar-nav-active-font-color;
  position: absolute;
  bottom: 7%;
  left: 0;
  height: 86%;
  width: 2px;
}

#service-sidebar .container-fluid {
  padding: unset;
  justify-content: flex-start;
  flex-flow: row wrap;
}

#service-sidebar .sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header-logo {
  height: $header-sidebar-logo-height;
  padding: 10px;
}

.sidebar-not-clickable-items {
  cursor: default;
}

#top-bar-sidebar-button {
  display: none;
}

@media (max-width: $sidebar-visibility-min-width) {
  #header-logo {
    height: $header-sidebar-logo-height-collapsed;
  }

  #service-sidebar {
    margin-left: -15rem;
  }

  #wrapper.toggled {
    overflow-x: hidden;
  }

  #wrapper.toggled #service-sidebar {
    margin-left: 0;
  }

  #top-bar-sidebar-button {
    display: initial;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// CONTENT
// ---------------------------------------------------------------------------------------------------------------------
#page-content-wrapper {
  background-color: $content-background-color;
  width: -webkit-stretch;
  width: -moz-available;
  width: stretch;
}

@media print {
  #page-content-wrapper {
    background-color: white;
  }
}

.page {
  margin: 1rem;
  width: -webkit-stretch;
  width: -moz-available;
  width: stretch;
}

@media (max-width: $sidebar-visibility-min-width) {
  .page {
    margin: 0;
    width: 100vw;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0.4rem;
}

.content-separator {
  width: 80%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
}

// ---------------------------------------------------------------------------------------------------------------------
// NOT FOUND
// ---------------------------------------------------------------------------------------------------------------------

#notfound {
  position: relative;
  height: 100vh
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0 auto 20px;
  z-index: -1
}

.notfound .notfound-404 h1 {
  font-family: montserrat, sans-serif;
  font-size: 236px;
  font-weight: 200;
  margin: 0;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.notfound .notfound-404 h2 {
  font-family: montserrat, sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
}

.notfound .notfound-401 h1 {
  font-family: montserrat, sans-serif;
  font-size: 236px;
  font-weight: 200;
  margin: 0;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.notfound .notfound-401 h2 {
  font-family: montserrat, sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
}

.notfound a {
  font-family: montserrat, sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background: #fcb817;
  font-size: 18px;
  -webkit-transition: .2s all;
  transition: .2s all
}

.notfound a:hover {
  color: #fcb817;
  background: #211b19
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0 auto 10px
  }

  .notfound .notfound-404 h1 {
    font-size: 86px
  }

  .notfound .notfound-404 h2 {
    font-size: 16px
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px
  }
}