@import "Variables";

.input-component-box {
    border-style: solid;
    border-width: 2px;
    border-color: $gray;
    border-radius: 5px;
    padding-bottom: 1rem;
}

.input_component-advanced {
    background-color: $content-advanced-background-color;
}

.input-component-title {
    font-size: 0.9rem;
    font-weight: 600;
}

.input-component-description {
    font-size: 0.8rem;
    color: $gray;
}

.input-component-valid-range-description {
    font-size: 0.8rem;
    color: $gray;
}

.input-component-button-modified {
    background-color: $color_range_010;
}

.input-component-text-container {
    padding-left: 0;
    padding-right: 0;
}