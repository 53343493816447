@import "Variables";

#dod-container {
    border-style: solid;
    border-width: 2px;
    border-color: $gray;
    border-radius: 5px;
    padding-bottom: 1rem;
}

#dod-title {
    font-size: 0.9rem;
    font-weight: 600;
}

#dod-description {
    font-size: 0.8rem;
    color: $gray;
}

#dod-reset-button {
    margin-right: 2rem;
}