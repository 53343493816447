@import "Variables";

#systems-delimiter {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
}

#system-table {
    padding-left: 2rem;
}

hr.rounded {
    border-top: 8px solid $color_range_base;
    border-radius: 5px;
}