@import "Variables";

.system-table-row-sh_operational {
    background-color: $color_range_002;
    color: rgb(0, 0, 0);
}

.system-table-row-ok {
    background-color: $color_range_002;
    color: rgb(0, 0, 0);
}

.system-table-row-sh_suspent {
    background-color: $color_range_011;
    color: rgb(0, 0, 0);
}

.system-table-row-sh_degraded {
    background-color: $color_range_009;
    color: rgb(0, 0, 0);
}

.system-table-row-sh_ota {
    background-color: $color_range_006;
    color: rgb(0, 0, 0);
}

.system-table-row-ota {
    background-color: $color_range_006;
    color: rgb(0, 0, 0);
}

.system-table-row-sh_fault {
    background-color: $color_range_014;
    color: rgb(255, 255, 255);
}

.system-table-row-error {
    background-color: $color_range_014;
    color: rgb(255, 255, 255);
}

.system-table-row-sh_shutdown {
    background-color: $color_range_013;
    color: rgb(0, 0, 0);
}

.system-table-row-offline {
    background-color: $color_range_013;
    color: rgb(0, 0, 0);
}

.system-table-grafana-icon {
    fill: $black;
}

#fleet-system-table tr {
    cursor: pointer;
}

.system-table-align-center-justify-left {
    display: flex;
    align-items: center;
    justify-content: left;
}