@import "Variables";

.navigation-back-button {
    color: $black;
    height: 4rem;
    width: 4rem;
    cursor: pointer;
}

.icon-button-text {
    padding-left: 0.5rem;
}

// .navigation-back-button a:link {
//     text-decoration: none;
// }

// .navigation-back-button a:visited {
//     text-decoration: none;
// }

// .navigation-back-button a:hover {
//     text-decoration: none;
// }

// .navigation-back-button a:active {
//     text-decoration: none;
// }