@import "Variables";

#system-link-row {
    padding-top: 0.5rem;
}

#system-backlink a {
    text-decoration: none;
}

#system-backlink-content {
    display: flex;
    justify-content: left;
    align-items: center;
}

#system-backlink-text {
    color: $black;
    font-size: x-large;
    font-weight: 600;
}

#grafana-link {
    display: flex;
    justify-content: flex-end;
}

.system-grafana-button {
    color: $black;
    height: 4rem;
    width: 4cqb;
    cursor: pointer;
}